 body {
   margin: 0;
 }

 .dropdown-style .mantine-Accordion-label {
   padding-top: 5px;
 }

 .category-accordio .mantine-Accordion-label {
   padding-bottom: 1px !important;
 }

 .category-accordio .mantine-Accordion-content {
   padding-bottom: 0 !important;
   padding-right: 0 !important;
   padding-left: 10px !important;
 }

 .category-accordion .mantine-Accordion-label {
   padding-bottom: 1px !important;
 }

 .category-accordion .mantine-Accordion-content {
   padding-bottom: 0 !important;
   padding-right: 0 !important;
   padding-left: 10px !important;
 }

 .layout-inner-design {
   margin-left: 3%;
   margin-right: 1%;
   /* max-width: 88vw; */
 }

 .login {
   height: 100vh;
 }

 .backGround-image {
   height: 375px;
 }

 .login-field {
   position: relative;
 }

 .login-form {
   width: 700px;
   position: absolute;
   top: -16%;
   /* left: 28%; */
 }

 .backGround-image2 {
   height: 385px;
 }

 .dashboard {
   height: 85vh;
 }

 .custom-accordian .mantine-Accordion-label {
   display: flex;
   padding-bottom: 0;
   padding-top: 0.3rem;
 }

 .custom-accordian .mantine-Accordion-item {
   padding-bottom: "2px";
 }

 /* .custom-accordian    '&[data-active]': {
      backgroundColor: '#EEF6FF',
        borderColor: '#D8E5F5',
        color: '#0094D1',
    } */

 /* .custom-accordian .mantine-Accordion-content {
   padding-bottom: 0;
   padding-top: 0rem;
 } */




 .custom-table .rdt_TableRow:not(:last-of-type) {
   /* border-bottom: none !important; */
 }

 div[data-tag="allowRowEvents"] {
   width: 100%;
 }

 .over-flowing {
   overflow-y: auto !important;
 }

 .fixed-height {
   min-height: 300px !important;
 }

 .table-height {
   min-height: 65vh !important;
 }

 .nav-bar .mantine-Accordion-content {
   padding-bottom: 0 !important;
 }

 .mantine-input-style-copy {
   height: 1.875rem;
   line-height: calc(2.125rem);
   appearance: none;
   resize: none;
   box-sizing: border-box;
   font-size: 0.65rem;
   width: 100%;
   color: #131133;
   display: block;
   text-align: left;
   border: 0.0625rem solid #ced4da;
   background-color: #FFF;
   transition: border-color 100ms ease;
   /* min-height: 1.875rem; */
   padding-left: calc(0.75rem);
   padding-right: calc(0.75rem);
   border-radius: 0.25rem;
 }

 .mantine-input-style-copy:focus {
   outline: none;
   border-color: #0066B1;
 }

 .mantine-input-style-copy[aria-invalid] {
   color: #fa5252;
   border-color: #fa5252;
 }

 .mantine-input-style-copy:disabled {
   background-color: #f1f3f5;
   color: #000;
   cursor: not-allowed;
   pointer-events: none;
 }

 .mantine-input-style-copy-phone {
   height: 1.875rem;
   -webkit-tap-highlight-color: transparent;
   line-height: calc(2.125rem);
   appearance: none;
   resize: none;
   box-sizing: border-box;
   font-size: 0.65rem;
   width: 100%;
   color: #131133;
   display: block;
   text-align: left;
   border: 0.0625rem solid #ced4da;
   background-color: #FFF;
   transition: border-color 100ms ease;
   min-height: 1.875rem;
   padding-left: calc(1.6rem);
   padding-right: calc(0.75rem);
   border-radius: 0.25rem;
 }

 .mantine-input-style-copy-phone:focus {
   outline: none;
   border-color: #0066B1;
 }

 .mantine-input-style-copy-phone:disabled {
   background-color: #f1f3f5;
   color: #909296;
   opacity: 0.6;
   cursor: not-allowed;
   pointer-events: none;
 }

 .mantine-input-style-copy-phone[aria-invalid] {
   color: #fa5252;
   border-color: #fa5252;
 }

 .custom-table table tbody tr:last-child td {
   /* border-bottom: none !important; */
 }

 .custom-table table thead tr th {
   border-right: 0px solid rgba(0, 0, 0, 0.12);
   border-bottom: 0px solid rgba(0, 0, 0, 0.12);
   color: var(--white);
   /* padding: 10px 15px; */
 }

 .custom-table table tbody tr td:last-child {
   border-right: 0px solid;
 }

 .custom-table table thead tr th:last-child {
   border-right: 0px solid;
 }

 .custom-table table tbody tr td {
   border-right: 1px solid rgba(0, 0, 0, 0.12);
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
 }

 .rdt_Pagination {
   border-top: none !important;
 }

 .custom-table .rdt_Table {
   border: 1px solid rgba(0, 0, 0, 0.12) !important;
 }

 .dashboard-table {
   background-color: #EEF3F7;
   font-size: 0.79rem;
   font-style: normal;
   font-weight: 500;
 }

 .dashboard-table  tr th {
   border-right: 0px solid rgba(0, 0, 0, 0.12);
   border-bottom: 0px solid rgba(0, 0, 0, 0.12);
   color:rgba(0, 0, 0, 0.87) !important;
   /* padding: 10px 15px; */
      font-size: 0.79rem !important;
        /* font-style: normal; */
        font-weight: 200 !important;
 }


 .phone-number-relative {
   position: relative;
 }

 .phone-number-absolute {
   top: 0%;
   left: 0%;
   position: absolute;
   height: 100%;
   /* background-color: #DCDCDC; */
   border-radius: 5px 0px 0px 5px;
   padding: 4px;
 }


 .uploader__placeholder {
   min-height: 70px !important;
   min-width: 70px !important;
   /* min-height: 89px !important;
        min-width: 89px !important; */
   overflow: visible !important;
   background-image: url('images/gallery-icon.svg') !important;
   background-repeat: no-repeat !important;
   background-position: center !important;
   border-radius: 0.25rem;
 }

 .uploader__file_input_label {
   top: -8% !important;
   left: 76% !important;
   transform: none !important;
 }

 .default-image-parent {
   position: relative;
 }

 .default-image {
   position: absolute;
   z-index: 1;
 }

 .default-image img {
   object-fit: contain !important;
   width: 70px !important;
   height: 70px !important;
 }

 /* .mantine-TextInput-label {
   font-size: 0.73rem;
 }

 .mantine-InputWrapper-label {
   font-size: 0.73rem;
 } */

 /* .mantine-Checkbox-label {
   font-size: 0.73rem;
 } */
 /* .form-table {
   min-width: 50vw;
   max-width: 65vw;
 } */

 .form-table .rdt_TableCell {
   padding-left: 0 !important;
   padding-right: 0 !important;
 }

 .mantine-RichTextEditor-toolbar {
   background: #F2F2F2 0% 0% no-repeat padding-box;
 }

 .mantine-RichTextEditor-control {
   background: #F2F2F2 0% 0% no-repeat padding-box;
 }

 .mantine-RichTextEditor-content {
   background: #F2F2F2 0% 0% no-repeat padding-box;
 }

 .pointer {
   cursor: pointer;
 }

 .mantine-input-style-copy {
   content: "+1" !important;
 }

 .mantine-Select-dropdown .dropdown-style {
   /* width: 100vw !important; */
   /* height: 10rem;
   overflow-y: auto; */
   /* overflow-x: auto; */
 }

 .category-accordio .mantine-Accordion-label {
   padding-bottom: 1px !important;
 }


 .dropdown-style .mantine-Accordion-label {
   padding-bottom: 1px !important;
 }


 .dropdown-style .mantine-Accordion-control {
   padding-left: 0 !important;
 }

 .dropdown-style .mantine-Accordion-content {
   padding-bottom: 0 !important;
   padding-right: 0 !important;
   /* padding-left: 2px !important; */
 }

 @media screen and (min-width: 1800px) {
   .login-form {
     position: absolute;
     top: -16%;
   }
 }

 @media screen and (max-width: 800px) {
   .login-form {
     width: 500px;
     position: absolute;
     top: -16%;
   }

 }

 @media screen and (max-width: 894px) {
   .login-form {
     width: 675px;
     position: absolute;
     top: -16%;
   }
 }

 @media screen and (max-width: 470px) {
   .moblie-display {
     display: none;
   }
 }

 .center-button-container {
   display: flex;
   justify-content: center !important;
   align-items: center !important;
   min-width: 98%;
   margin: 2px;
 }

 .accordion-tree-select .mantine-Select-dropdown {
   background-color: white;
   z-index: 100 !important;
 }

 .accordion-tree-select .mantine-Select-dropdown div {
   max-height: none !important;
   /* display: flex; */
   justify-content: center;
   width: 100%;
 }

 /* .accordion-tree-select .mantine-Group-root {
   gap: 0rem
 }

 .accordion-tree-select .mantine-Accordion-label {
   font-size: 0.635rem !important;
 } */


 .accordion-tree-select .mantine-Accordion-item {
   width: max-content;
   color: white;
   border: none;
 }

 .refInput {
   display: none;
   border: none;
   margin: 0;
   width: 0px;
   height: 0px;
   padding: 0px;
 }

 .refInput:focus-visible {
   outline: unset;
 }