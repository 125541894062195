body {
  font-family: 'Manrope';
  /* font-size: 1rem; */
}

@font-face {
  font-family: 'Manrope';
  src: url('Manrope-Medium.ttf') format('ttf'), url('Manrope-Medium.ttf') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('Manrope-SemiBold.ttf') format('ttf'), url('Manrope-SemiBold.ttf') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}